/*

Welcome to the Fontawesome Bundler 

for more info reference here

https://fontawesome.com/how-to-use/on-the-web/advanced/svg-javascript-core
https://fontawesome.com/how-to-use/with-the-api/setup/getting-started
 

if you aren't using a weight at all it needs to be completely commented out

	*/


import { config , library, dom, icon } from 'Node/@fortawesome/fontawesome-svg-core';
config.searchPseudoElements = true

//light
import { 
	faPrint,
	faAddressCard,
	faBadgeCheck as falBadgeCheck,
	faCheckCircle,
	faExclamationCircle,
	faPodium,
	faBook,
	faPlay,
	faUsers as falUsers,
	faPaperclip,
	faCalendarAlt,
	faCart,
	faEllipsisV,
	faLongArrowLeft,
	faMapMarkerAlt,
	faTachometer,
	faCalendarDay,
	faChevronLeft,
	faChevronRight,
	faTicket,
	faTrophy,
	faFilePdf,
    faFilter,
	faFileAlt,
	faPlayCircle,
	faCalendarPlus,
	faThumbsUp,
	faShareSquare,
	faPlus,
	faSignOut,
	faIdBadge as falIdBadge,
	faUserGraduate,
	faUser as falUser,
  	faUserPlus,
	faCalendar,
	faBell,
	faBuilding,
	faIdCardAlt,
	faLockAlt as falLockAlt,
	faArrowToLeft,
	faExternalLinkSquare,
	faHospital,
	faCloudDownload,
	faClock as falClock,
	faSearch as falSearch,
	faTimes as falTimes,
	faLock as falLock,
	faCommentAltLines as falCommentAltLines,
	faLongArrowRight as falLongArrowRight,
	faTv as falTv,
	faMapMarkedAlt as falMapMarkedAlt,
	faFileDownload as falFileDownload,
	faPhone as falPhone,
	faMapMarkerAlt as falMapMarkerAlt,
	faEnvelope as falEnvelope,
	faShoppingCart as falShoppingCart,
	faStar as falStar,
	faLink as falLink,
    faInfoCircle as falInfoCircle,
    faCheck as falCheck,
	faAddressBook,
    faUserMd,
    faChartPie,
    faUserFriends,
	faGraduationCap,
	faHandHoldingHeart as falHandHoldingHeart,
	faCommentAltCheck as falCommentAltCheck,


} from 'Node/@fortawesome/pro-light-svg-icons';


// //regular
import { 
	faTimes as farTimes,
	faLongArrowRight,
	faChevronDown,
	faBaby as farBaby,
    faLongArrowDown,
	faSpinner,
	faLongArrowAltRight,
	faLongArrowAltLeft,
	faMapMarker as farMapMarker,
	faLongArrowRight as farLongArrowRight,
	faCheck as farCheck,
    faSearch as farSearch,
	faUser as farUser,
	faLockAlt as farLockAlt,
  faExternalLinkAlt as farExternalLinkAlt,
    

} from 'Node/@fortawesome/pro-regular-svg-icons';

//solid
import { 
	
	faChild,
	faRestroom,
	faBaby as fasBaby,
	faCaretDown,
	faUsers as fasUsers,
	faCaretUp,
	faTachometerFastest,
	faCommentAltLines as fasCommentAltLines,
	faTachometerSlowest,
	faTachometerFast,
	faUserCircle,
	faArrowAltSquareUp,
	faEnvelopeSquare,
	faHourglassHalf,
	faExclamationTriangle,
	faInfoSquare,
	faBadgeCheck as fasBadgeCheck,
	faUser as fasUser,
	faLock as fasLock,
	faTimes as fasTimes,
	faCheck as fasCheck,
	faClock as fasClock,
	faShoppingCart as fasShoppingCart,
	faInfoCircle as fasInfoCircle,
	faEnvelope as fasEnvelope,
	faPhone as fasPhone,
	faSearch as fasSearch,
	faTv as fasTv,
	faMapMarkerAlt as fasMapMarkerAlt,
	faFileDownload as	fasFileDownload,
	faLink as fasLink,
	faIdBadge as fasIdBadge,


} from 'Node/@fortawesome/pro-solid-svg-icons';



// Brands
import { 
	faTwitterSquare,
	faFacebookSquare,
	faFacebookF,
	faInstagram,
	faLinkedin,
	faLinkedinIn,
	faTwitter,

	

} from 'Node/@fortawesome/free-brands-svg-icons';


library.add( 


	// arrows
	falLongArrowRight,
	farLongArrowRight,
	faLongArrowLeft,
    faLongArrowDown,
	faChevronDown,
	falTimes,
	farTimes,
	fasTimes,
	faChevronLeft,
	faChevronRight,
	faCaretDown,
	faCaretUp,
	faArrowAltSquareUp,
	farCheck,
	fasCheck,
    falCheck,
    farSearch,
	faLongArrowAltRight,
	faLongArrowAltLeft,
	faSpinner,
	faPaperclip,
	
	// event icons
	faCalendarAlt,
    faCalendar,
	falShoppingCart,
	fasShoppingCart,
	faTicket,	
	faCalendarAlt,
	falMapMarkedAlt,
	farMapMarker,
	falMapMarkerAlt,
	fasMapMarkerAlt,
	faCalendarDay,
	faCalendarPlus,
    faFilter,

	//cpd
	faTachometerFastest,
	faUserCircle,
	faTachometerSlowest,
	faTachometerFast,
	faTachometer,
	faHourglassHalf,
	faExclamationTriangle,
	fasBadgeCheck,
	falBadgeCheck,

	//docs
	faEllipsisV,
	faFileAlt,
	faFilePdf,
	fasFileDownload,
	falFileDownload,
	faCloudDownload,
	faPrint,

	//contact
	fasEnvelope,
	falEnvelope,

	falPhone,
	fasPhone,

	//general
	fasLock,
	faPodium,
	falLock,
	faUserPlus,
	faInfoSquare,
	falStar,
	falTv,
	fasTv,
	faPlayCircle,
	falUsers,
	fasUsers,
    faUserFriends,
	faThumbsUp,
	faShareSquare,
	faPlus,
	falSearch,
	fasSearch,
	faSignOut,
	faIdCardAlt,
	farLockAlt,
	falLockAlt,
	faArrowToLeft, 
	fasIdBadge,
    falIdBadge,
	faUserGraduate,
	faCalendar,
	faBell,
	faBuilding,
	faSpinner,
	fasClock,
	fasUser,
	falUser,
    farUser,
	falClock,
	fasLink,
	falLink,
	faTrophy,
  	falInfoCircle,
  	fasInfoCircle,
	faExclamationCircle,
  	faExternalLinkSquare,
	faHospital,
	faChild,
	faRestroom,
	fasBaby,
	farBaby,
	faBook,
	faPlay,
	fasCommentAltLines,
	falCommentAltLines,
	faAddressCard,
	faAddressBook,
    faUserMd,
    faChartPie,
    faCheckCircle,
    faGraduationCap,
  farExternalLinkAlt,
  falHandHoldingHeart,
  falCommentAltCheck,

	//social
	faFacebookSquare,
	faTwitterSquare,
	faEnvelopeSquare,

	faLinkedin,
	faTwitter,
	faFacebookF,
	faInstagram,
	faLinkedinIn,

	);



//Brands
// import { 



// } from 'Node/@fortawesome/free-brands-svg-icons';



// insert css to reference for style sheets
const css = dom.css()



dom.insertCss(css)

dom.watch()